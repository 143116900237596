
body {
    font-family: Rough;
    margin: 0px;
    background-color: black;
}

$beige: #be8a56;
$green: #156936;
$green2: #28b45f;
$buttonsize: 36px;

a {
  text-decoration: none;
  color: #90a1c4;
}

#maincontainer {
  overflow: hidden;
  position: relative;
  color: white;
  min-width: 1330px;
  min-height: 1000px;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-image: url('../files/background\ overlay.png');
}

@font-face {
  font-family: Rough;
  src: url('../files/rough.otf');
}


@font-face {
  font-family: Gilroy;
  src: url('../files/Gilroy-Regular.ttf');
}

#walletbuttoncontainer {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translate(310px, 0);
}

.walletbutton {
  cursor: pointer;
  width: 168px;
  border-radius: 12px;
  box-shadow: 2px 2px 5px 2px $green;
  border: 2px solid $green2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $beige;
  height: 40px;
  color: black;
  font-size: 20pt;
}

.walletbutton:hover {
  box-shadow: 2px 2px 5px 2px $green;
  
}

#logo {
  background-image: url('../files/logo.png');
  background-size: cover;
  width: 220px;
  height: 188px;
  top: 40px;
  position: absolute;
  left: 50%;
  transform: translate(-435px, 0);
}

#mainimage {
  background-image: url('../files/mainimg.png');
  position: absolute;
  width: 400px;
  height: 456px;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(20%, -50%);
}

#img1 {
  background-image: url('../files/1.png');
  position: absolute;
  width: 108px;
  height: 123px;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(20%, -270px);
}

#img2 {
  background-image: url('../files/2.png');
  position: absolute;
  width: 108px;
  height: 123px;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(20%, 140px);
}

#img3 {
  background-image: url('../files/3.png');
  position: absolute;
  width: 108px;
  height: 123px;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(444px, -52px);
}


#footertxt {
  font-family: Gilroy;
  text-align: center;
  width: 80%;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform:translateX(-50%)
}

#footerimg {
  background-image: url('../files/footer.png');
  width: 197px;
  height: 38px;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
}

//MINT SECTIOM
#mintwrapper {
  width: 360px;
  height: 470px;
  font-size: 20pt;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-119%, -48%);
}

#title1 {
  font-size: 50pt;
  letter-spacing: 1px;
}

#title2 {
  margin-top: -20px;
  letter-spacing: 1px;
  font-size: 50pt;
  margin-bottom: 20px;
}

#line {
  width: 100%;
  height: 3px;
  background-color: white;
  margin-bottom: 30px;
}

#mintmessage {
  font-family: Gilroy;
  font-size: 13pt;
  width: 100%;
  height: 50px;
}

#W-count {
  display: flex;
  width: 360px;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
}

.W-timebox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75px;
}

.W-time {
  width: 60px;
  border-radius: 12px;
  height: 60px;
  color: black;
  display: flex;
  font-family: Rough;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: solid 4px black;
  font-size: 35px;
}

.W-timetype {
  margin-top: 8px;
  color: white;
  font-size: 14pt;
  font-family: Gilroy;
}

#mint-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

#count-buttons {
  display: flex;
  align-items: center;
}

#minus {
  background-color: $beige;
  width: $buttonsize;
  height: $buttonsize;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

#leftbullet {
  width: 27.5px;
  height: 9px;
  background-image: url('../files/lbullet.png');
  background-size: cover;
}

#countbox {
  height: 60px;
  width: 60px;
  background-color: #c4c4c4;
  border-radius: 6px;
  box-shadow: 2px 2px 4px .2px black inset;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 28pt;
}

#rightbullet {
  width: 27.5px;
  height: 9px;
  background-image: url('../files/rbullet.png');
  background-size: cover;
  
}

#plus {
  background-color: $beige;
  width: $buttonsize;
  height: $buttonsize;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
#fakemint {
  opacity: .7;
}
.mintbutton {
  cursor: pointer;
  width: 168px;
  border-radius: 12px;
  box-shadow: 2px 2px 5px 2px $green;
  border: 2px solid $green2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $beige;
  height: 40px;
  color: black;
  font-size: 20pt;
}

.mintbutton:hover {
  box-shadow: 2px 2px 5px 2px $green;
  
}

@media only screen and (max-width: 500px) {
  #maincontainer {
    min-width: 300px;
    min-height: 1600px;
  }

  #supplytimer {
    text-align: center;
  }

  #walletbuttoncontainer {
    top: 40px;
    right: 30px;
    left: auto;
    transform: translate(0, 0);
  }

  #logo {
    width: 100px;
    height: 85px;
    top: 24px;
    left: 30px;
    transform: translate(0, 0);
  }

  #mainimage {
    width: 270px;
    height: 307.8px;
    top: 180px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  
  #img1 {
    width: 86px;
    height: 98px;
    top: 160px;
    left: 50%;
    transform: translate(-170px, 0);
  }
  
  #img2 {
    width: 86px;
    height: 98px;
    top: 430px;
    left: 50%;
    transform: translate(-170px, 0);
  }
  
  #img3 {
    width: 86px;
    height: 98px;
    top: 290px;
    left: 50%;
    transform: translate(95px, 0);
  }

  #mintwrapper {
    width: 310px;
    height: 820px;
    left: 50%;
    top: 540px;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #mint-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  
  #plus {
    margin-right: 0px;
  }

  #count-buttons {
    margin-bottom: 20px;
  }

  #mintmessage {
    text-align: center;
  }

  #W-count {
    align-items: center;
    margin-left: 0px;
    flex-wrap: wrap;
    justify-content: center;
    width: 300px;
    margin-bottom: 18px;
    margin-top: 30px
  }

  .W-timebox {
      margin-left: 28px;
      margin-right: 28px;
      margin-bottom: 10px;
      margin-top: 10px;
      width: 75px;
  }

  .W-timetype {
      margin-bottom: 10px;
  }

}